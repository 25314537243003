import { useDebounceValue } from 'usehooks-ts'
import { useOrganizationList } from '@clerk/shared/react'
import { Loader } from 'lucide-react'
import Image from 'next/image'
import { useRouter } from 'next/navigation'

import { client } from '~/app/_trpc/client'
import {
	CommandInput,
	CommandEmpty,
	CommandGroup,
	CommandItem,
	Command,
} from '~/ui/shadcn/ui/command'

const SelectContent = ({
	setOpen,
}: {
	setOpen: (open: boolean) => void
}) => {
	const [
		organizationSearchQuery,
		setOrganizationSearchQuery,
	] = useDebounceValue('', 420)

	const {
		setActive,
		isLoaded,
	} = useOrganizationList()

	const {
		data: organizations,
		isLoading,
	} = client.clerk.searchOrganizations.useQuery({
		query: organizationSearchQuery,
	}, {
		cacheTime: 0,
	})

	const router = useRouter()

	return (
		<Command shouldFilter={false}>
			<CommandInput
				defaultValue={organizationSearchQuery}
				onValueChange={(value) => {
					setOrganizationSearchQuery(value)
				}}
				placeholder="Search for an Organization..."
			/>
			{isLoading || !isLoaded ? (
				<div className="flex items-center justify-center p-3">
					<Loader className="size-4 animate-spin" />
				</div>
			) : (
				<>
					<CommandEmpty className="p-2">
						No Organization found.
					</CommandEmpty>
					<CommandGroup className="p-2">
						{organizations?.map((organization) => {
							return (
								<CommandItem
									key={organization.id}
									value={organization.id}
									onSelect={async () => {
										await setActive({
											organization: organization.id,
										})
										router.push('/')
										setOpen(false)
									}}
									className="flex cursor-pointer items-center justify-between gap-3"
								>
									<div className="flex items-center gap-3">
										<div className="relative aspect-square size-8 rounded bg-black">
											{organization.hasImage ? (
												<Image
													src={organization.imageUrl}
													priority={true}
													alt={organization.name}
													title={organization.name}
													className="rounded object-cover"
													fill={true}
												/>
											) : null}
										</div>
										<div>
											{organization.name}
										</div>
									</div>
								</CommandItem>
							)
						})}
					</CommandGroup>
				</>
			)}
		</Command>
	)
}

export default SelectContent
